/*! VARIABLES */
/*#region variables*/
/* Set root variables */
:root {
	/*primary #34333d*/
	--primary-h: 246;
	--primary-c: 8.93%;
	--primary-l: 21.96%;
	--primary-color: hsl(var(--primary-h), var(--primary-c), var(--primary-l));
	--primary-light-color: hsl(
		var(--primary-h),
		var(--primary-c),
		calc(var(--primary-l) + (var(--primary-l) * 1.1))
	);

	--primary-lighter-color: hsl(
		var(--primary-h),
		var(--primary-c),
		calc(var(--primary-l) + (var(--primary-l) * 1.2))
	);
	--primary-dark-color: hsl(
		var(--primary-h),
		var(--primary-c),
		calc(var(--primary-l) - (var(--primary-l) * 0.15))
	);
	--primary-darker-color: hsl(
		var(--primary-h),
		var(--primary-c),
		calc(var(--primary-l) - (var(--primary-l) * 0.85))
	);
	/*highlight-bg #ffffff*/
	--highlight-bg-h: 0;
	--highlight-bg-s: 0%;
	--highlight-bg-l: 100%;
	/*secondary #676675*/
	--secondary-h: 244;
	--secondary-c: 6.85%;
	--secondary-l: 42.94%;
	--secondary-color: hsl(
		var(--secondary-h),
		var(--secondary-c),
		var(--secondary-l)
	);
	--secondary-light-color: hsl(
		var(--secondary-h),
		var(--secondary-c),
		calc(var(--secondary-l) + (var(--secondary-l) * 1.1))
	);
	--secondary-lighter-color: hsl(
		var(--secondary-h),
		var(--secondary-c),
		calc(var(--secondary-l) + (var(--secondary-l) * 1.2))
	);
	--secondary-dark-color: hsl(
		var(--secondary-h),
		var(--secondary-c),
		calc(var(--secondary-l) - (var(--secondary-l) * 0.15))
	);
	/*success #28a745*/
	--success-h: 133.7;
	--success-c: 61.35%;
	--success-l: 40.59%;
	--success-color: hsl(var(--success-h), var(--success-c), var(--success-l));
	--success-light-color: hsl(
		var(--success-h),
		var(--success-c),
		calc(var(--success-l) + (var(--success-l) * 1.1))
	);
	--success-lighter-color: hsl(
		var(--success-h),
		var(--success-c),
		calc(var(--success-l) + (var(--success-l) * 1.2))
	);
	--success-dark-color: hsl(
		var(--success-h),
		var(--success-c),
		calc(var(--success-l) - (var(--success-l) * 0.15))
	);
	/*info #17a2b8*/
	--info-h: 188.2;
	--info-c: 77.78%;
	--info-l: 40.59%;
	--info-color: hsl(var(--info-h), var(--info-c), var(--info-l));
	--info-light-color: hsl(
		var(--info-h),
		var(--info-c),
		calc(var(--info-l) + (var(--info-l) * 1.1))
	);
	--info-lighter-color: hsl(
		var(--info-h),
		var(--info-c),
		calc(var(--info-l) + (var(--info-l) * 1.2))
	);
	--info-dark-color: hsl(
		var(--info-h),
		var(--info-c),
		calc(var(--info-l) - (var(--info-l) * 0.15))
	);
	/*warning #ffc107*/
	--warning-h: 45;
	--warning-c: 100%;
	--warning-l: 51.37%;
	--warning-color: hsl(var(--warning-h), var(--warning-c), var(--warning-l));
	--warning-light-color: hsl(
		var(--warning-h),
		var(--warning-c),
		calc(var(--warning-l) + (var(--warning-l) * 1.1))
	);
	--warning-lighter-color: hsl(
		var(--warning-h),
		var(--warning-c),
		calc(var(--warning-l) + (var(--warning-l) * 1.2))
	);
	--warning-dark-color: hsl(
		var(--warning-h),
		var(--warning-c),
		calc(var(--warning-l) - (var(--warning-l) * 0.15))
	);
	/*danger #dc3545*/
	--danger-h: 354.25;
	--danger-c: 70.46%;
	--danger-l: 53.53%;
	--danger-color: hsl(var(--danger-h), var(--danger-c), var(--danger-l));
	--danger-light-color: hsl(
		var(--danger-h),
		var(--danger-c),
		calc(var(--danger-l) + (var(--danger-l) * 1.1))
	);
	--danger-lighter-color: hsl(
		var(--danger-h),
		var(--danger-c),
		calc(var(--danger-l) + (var(--danger-l) * 1.2))
	);
	--danger-dark-color: hsl(
		var(--danger-h),
		var(--danger-c),
		calc(var(--danger-l) - (var(--danger-l) * 0.15))
	);
	/*light #f8f9fa*/
	--light-h: 210;
	--light-c: 16.67%;
	--light-l: 97.65%;
	--light-color: hsl(var(--light-h), var(--light-c), var(--light-l));
	--light-light-color: hsl(
		var(--light-h),
		var(--light-c),
		calc(var(--light-l) + (var(--light-l) * 1.1))
	);
	--light-lighter-color: hsl(
		var(--light-h),
		var(--light-c),
		calc(var(--light-l) + (var(--light-l) * 1.2))
	);
	--light-dark-color: hsl(
		var(--light-h),
		var(--light-c),
		calc(var(--light-l) - (var(--light-l) * 0.01))
	);
	/*dark #343a40*/
	--dark-h: 210;
	--dark-c: 10.34%;
	--dark-l: 22.75%;
	--dark-color: hsl(var(--dark-h), var(--dark-c), var(--dark-l));
	--dark-light-color: hsl(
		var(--dark-h),
		var(--dark-c),
		calc(var(--dark-l) + (var(--dark-l) * 1.1))
	);
	--dark-lighter-color: hsl(
		var(--dark-h),
		var(--dark-c),
		calc(var(--dark-l) + (var(--dark-l) * 1.2))
	);
	--dark-dark-color: hsl(
		var(--dark-h),
		var(--dark-c),
		calc(var(--dark-l) - (var(--dark-l) * 0.15))
	);
	/*pink #e815ae*/
	--pink-h: 316.49;
	--pink-c: 83.4%;
	--pink-l: 49.61%;
	--pink-color: hsl(var(--pink-h), var(--pink-c), var(--pink-l));
	--pink-light-color: hsl(
		var(--pink-h),
		var(--pink-c),
		calc(var(--pink-l) + (var(--pink-l) * 1.1))
	);
	--pink-lighter-color: hsl(
		var(--pink-h),
		var(--pink-c),
		calc(var(--pink-l) + (var(--pink-l) * 1.1))
	);
	--pink-dark-color: hsl(
		var(--pink-h),
		var(--pink-c),
		calc(var(--pink-l) - (var(--pink-l) * 0.15))
	);
	/*purple #800080 */
	--purple-h: 300;
	--purple-c: 100%;
	--purple-l: 25.1%;
	--purple-color: hsl(var(--purple-h), var(--purple-c), var(--purple-l));
	--purple-light-color: hsl(
		var(--purple-h),
		var(--purple-c),
		calc(var(--purple-l) + (var(--purple-l) * 1.1))
	);
	--purple-lighter-color: hsl(
		var(--purple-h),
		var(--purple-c),
		calc(var(--purple-l) + (var(--purple-l) * 1.2))
	);
	--purple-dark-color: hsl(
		var(--purple-h),
		var(--purple-c),
		calc(var(--purple-l) - (var(--purple-l) * 0.15))
	);
	/*logo-dark #00dfdf */
	--logo-dark-h: 180;
	--logo-dark-c: 100%;
	--logo-dark-l: 43.73%;
	--logo-dark-color: hsl(
		var(--logo-dark-h),
		var(--logo-dark-c),
		var(--logo-dark-l)
	);
	--logo-dark-light-color: hsl(
		var(--logo-dark-h),
		var(--logo-dark-c),
		calc(var(--logo-dark-l) + (var(--logo-dark-l) * 1.1))
	);
	--logo-dark-lighter-color: hsl(
		var(--logo-dark-h),
		var(--logo-dark-c),
		calc(var(--logo-dark-l) + (var(--logo-dark-l) * 1.2))
	);
	--logo-dark-dark-color: hsl(
		var(--logo-dark-h),
		var(--logo-dark-c),
		calc(var(--logo-dark-l) - (var(--logo-dark-l) * 0.15))
	);
	/*slate #495f78  */
	--slate-h: 211.91;
	--slate-c: 24.35%;
	--slate-l: 37.84%;
	--slate-color: hsl(var(--slate-h), var(--slate-c), var(--slate-l));
	--slate-light-color: hsl(
		var(--slate-h),
		var(--slate-c),
		calc(var(--slate-l) + (var(--slate-l) * 1.1))
	);
	--slate-lighter-color: hsl(
		var(--slate-h),
		var(--slate-c),
		calc(var(--slate-l) + (var(--slate-l) * 1.2))
	);
	--slate-dark-color: hsl(
		var(--slate-h),
		var(--slate-c),
		calc(var(--slate-l) - (var(--slate-l) * 0.15))
	);
	/*ochre #7e684c*/
	--ochre-h: 33.6;
	--ochre-c: 24.75%;
	--ochre-l: 39.61%;
	--ochre-color: hsl(var(--ochre-h), var(--ochre-c), var(--ochre-l));
	--ochre-light-color: hsl(
		var(--ochre-h),
		var(--ochre-c),
		calc(var(--ochre-l) + (var(--ochre-l) * 1.1))
	);
	--ochre-lighter-color: hsl(
		var(--ochre-h),
		var(--ochre-c),
		calc(var(--ochre-l) + (var(--ochre-l) * 1.2))
	);
	--ochre-dark-color: hsl(
		var(--ochre-h),
		var(--ochre-c),
		calc(var(--ochre-l) - (var(--ochre-l) * 0.15))
	);
	/*Organic #467454*/
	--organic-h: 138.26;
	--organic-c: 24.73%;
	--organic-l: 36.47%;
	--organic-color: hsl(var(--organic-h), var(--organic-c), var(--organic-l));
	--organic-light-color: hsl(
		var(--organic-h),
		var(--organic-c),
		calc(var(--organic-l) + (var(--organic-l) * 1.1))
	);
	--organic-lighter-color: hsl(
		var(--organic-h),
		var(--organic-c),
		calc(var(--organic-l) + (var(--organic-l) * 1.2))
	);
	--organic-dark-color: hsl(
		var(--organic-h),
		var(--organic-c),
		calc(var(--organic-l) - (var(--organic-l) * 0.15))
	);
	/*Plum #784949*/
	--plum-h: 0;
	--plum-c: 24.35%;
	--plum-l: 37.84%;
	--plum-color: hsl(var(--plum-h), var(--plum-c), var(--plum-l));
	--plum-light-color: hsl(
		var(--plum-h),
		var(--plum-c),
		calc(var(--plum-l) + (var(--plum-l) * 0.25))
	);
	--plum-lighter-color: hsl(
		var(--plum-h),
		var(--plum-c),
		calc(var(--plum-l) + 50%)
	);
	--plum-dark-color: hsl(
		var(--plum-h),
		var(--plum-c),
		calc(var(--plum-l) - 5%)
	);
	/*Mineral #2c2c2c*/
	--mineral-h: 0;
	--mineral-c: 0%;
	--mineral-l: 17.25%;
	--mineral-color: hsl(var(--mineral-h), var(--mineral-c), var(--mineral-l));
	--mineral-light-color: hsl(
		var(--mineral-h),
		var(--mineral-c),
		calc(var(--mineral-l) + (var(--mineral-l) * 1.1))
	);
	--mineral-lighter-color: hsl(
		var(--mineral-h),
		var(--mineral-c),
		calc(var(--mineral-l) + (var(--mineral-l) * 1.2))
	);
	--mineral-dark-color: hsl(
		var(--mineral-h),
		var(--mineral-c),
		calc(var(--mineral-l) - (var(--mineral-l) * 0.15))
	);
	--txt-primary: #fff;
	--txt-secondary: #fff;
	--txt-info: #000;
	--txt-success: #fff;
	--txt-warning: #000;
	--txt-danger: #fff;
	--txt-light: #000;
	--txt-dark: #fff;
	--txt-pink: #fff;
	--txt-purple: #fff;
	--txt-logo-dark: #000;
	--txt-slate: #fff;
	--txt-ochre: #fff;
	--txt-organic: #fff;
	--txt-plum: #fff;
	--txt-mineral: #fff;

	--background: #fff;
}
/*#endregion*/
/*! COMMON TAGS*/
/*#region commons*/
html,
body {
	font:
		14px "Lucida Grande",
		Helvetica,
		Arial,
		sans-serif;
	width: 100% !important;
	background-color: var(--background);
}

.bg-img {
	background: linear-gradient(
		19deg,
		rgba(220, 220, 220, 0.7),
		rgba(255, 255, 255, 0.5)
	);
	/*Image opacity*/
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
}

.block {
	padding: 4px;
}
a {
	color: var(--secondary-color);
}
/*#endregion*/
/*! TEXTS */
/*#region fonts*/

/*Text with circle background color bg-warning-gradient*/

.circle-text {
	display: inline-block;
	padding: 5px 10px;
	/*Border radius 10px only on top*/
	border-radius: 6px 6px 0 0;
	/*Transform background image to be centered from text*/
	background-position: center;
	background-size: 100% 100%;
	margin: 0;
	/*Height = width*/
	width: 100%;
	max-width: 100%;
	height: auto;
	/*Align text center H and V*/
	text-align: center;
	/*-45° rotation*/
	font-size: 14px !important;
	font-weight: bold;
	/*Overflow ellipsis*/
	overflow: hidden;
	text-overflow: ellipsis;
}

.circle-text-promo {
	border-radius: 10px;
	margin: -55px 0;
	/*Height = width*/
	width: auto;
	max-width: 100%;
	height: auto;
}

.font-sm {
	font-size: 10px;
}
.font-md {
	font-size: 12px;
}
.font-lg {
	font-size: 14px;
}
.font-xl {
	font-size: 16px;
}
.font-bold {
	font-weight: bold !important;
}
.font-bolder {
	font-weight: bolder !important;
}
.txt-header-title {
	margin-top: 10px;
	max-width: 250px !important;
	white-space: wrap !important;
	overflow-x: hidden !important;
	overflow-y: hidden !important;
}
@media screen and (max-width: 700px) {
	.txt-header-title {
		max-width: 120px !important;
		margin: 0 auto;
	}
	.link-header-title {
		max-width: 180px !important;
	}
	.font-sm {
		font-size: 12px;
	}
	.font-md {
		font-size: 14px;
	}
	.font-lg {
		font-size: 16px;
	}
	.font-xl {
		font-size: 18px;
	}
}

/*#endregion*/
/*#region text color */
/*! Bootstrap override*/
.form-label {
	font-size: 12px !important;
	font-weight: bold !important;
}

.opacity-mid {
	opacity: 0.7;
}

.text-primary {
	color: var(--primary-color) !important;
}
.text-info {
	color: var(--info-color) !important;
}
.text-success {
	color: var(--success-color) !important;
}
.text-warning {
	color: var(--warning-color) !important;
}
.text-danger {
	color: var(--danger-color) !important;
}

.txt-dark {
	color: var(--dark-color) !important;
}
.txt-light {
	color: var(--light-color) !important;
}
.txt-primary {
	color: var(--txt-primary) !important;
}
.txt-info {
	color: var(--txt-info) !important;
}
.txt-success {
	color: var(--txt-success) !important;
}
.txt-warning {
	color: var(--txt-warning) !important;
}
.txt-danger {
	color: var(--txt-danger) !important;
}

.txt-Ochre {
	color: var(--ochre-color) !important;
}

.txt-Slate {
	color: var(--slate-color) !important;
}

.txt-Organic {
	color: var(--organic-color) !important;
}

.txt-Plum {
	color: var(--plum-color) !important;
}

.txt-Mineral {
	color: var(--mineral-color) !important;
}
/*#endregion*/
/*! BACKGROUND */
/*#region background*/
.bg-dark-transparent {
	background-color: rgba(0, 0, 0, 0.5) !important;
	color: #fff !important;
}

.bg-primary {
	background-color: var(--primary-color) !important;
	color: var(--txt-primary) !important;
}
.bg-primary-gradient {
	background-image: linear-gradient(
		19deg,
		var(--primary-color) 0%,
		var(--primary-light-color) 100%
	) !important;
	color: var(--txt-primary) !important;
}
.bg-primary-light {
	background-color: var(--primary-light-color) !important;
}
.bg-primary-light-gradient {
	background-image: linear-gradient(
		19deg,
		var(--primary-light-color) 0%,
		var(--primary-lighter-color) 100%
	) !important;
	color: var(--txt-primary) !important;
}
.bg-primary-lighter {
	background-color: var(--primary-lighter-color) !important;
}
.bg-primary-dark {
	background-color: var(--primary-dark-color) !important;
}

.bg-primary-dark-gradient {
	background-image: linear-gradient(
		19deg,
		var(--primary-darker-color) 0%,
		var(--primary-color) 100%
	) !important;
	color: var(--light-color) !important;
}

.bg-secondary {
	background-color: var(--secondary-color) !important;
	color: var(--txt-secondary) !important;
}
.bg-secondary-gradient {
	background-image: linear-gradient(
		19deg,
		var(--secondary-color) 0%,
		var(--secondary-light-color) 100%
	) !important;
	color: var(--txt-secondary) !important;
}
.bg-secondary-light {
	background-color: var(--secondary-light-color) !important;
}
.bg-secondary-lighter {
	background-color: var(--secondary-lighter-color) !important;
}
.bg-secondary-dark {
	background-color: var(--secondary-dark-color) !important;
}
.bg-light {
	background-color: var(--light-color) !important;
	color: var(--txt-light) !important;
}
.bg-light-light {
	background-color: var(--light-light-color) !important;
}
.bg-light-lighter {
	background-color: var(--light-lighter-color) !important;
}
.bg-light-dark {
	background-color: var(--light-dark-color) !important;
}
.bg-dark {
	background-color: var(--dark-color) !important;
	color: var(--txt-dark) !important;
}
.bg-dark-light {
	background-color: var(--dark-light-color) !important;
}
.bg-dark-lighter {
	background-color: var(--dark-lighter-color) !important;
}
.bg-dark-dark {
	background-color: var(--dark-dark-color) !important;
}
.bg-pink {
	background-color: var(--pink-color) !important;
	color: var(--txt-pink);
}
.bg-pink-light {
	background-color: var(--pink-light-color) !important;
}
.bg-pink-lighter {
	background-color: var(--pink-lighter-color) !important;
}
.bg-pink-dark {
	background-color: var(--pink-dark-color) !important;
}
.bg-purple {
	background-color: var(--purple-color) !important;
	color: var(--txt-purple) !important;
}
.bg-purple-light {
	background-color: var(--purple-light-color) !important;
}
.bg-purple-lighter {
	background-color: var(--purple-lighter-color) !important;
}
.bg-purple-dark {
	background-color: var(--purple-dark-color) !important;
}
.bg-success {
	background-color: var(--success-color) !important;
	color: var(--txt-success) !important;
}
.bg-success-gradient {
	background-image: linear-gradient(
		19deg,
		var(--success-color) 0%,
		var(--success-light-color) 100%
	) !important;
	color: var(--txt-success) !important;
}
.bg-success-light {
	background-color: var(--success-light-color) !important;
}
.bg-success-lighter {
	background-color: var(--success-lighter-color) !important;
}
.bg-success-dark {
	background-color: var(--success-dark-color) !important;
}
.bg-info {
	background-color: var(--info-color) !important;
	color: var(--txt-info) !important;
}
.bg-info-gradient {
	background-image: linear-gradient(
		19deg,
		var(--info-color) 0%,
		var(--info-light-color) 100%
	) !important;
	color: var(--txt-info) !important;
}
.bg-info-light {
	background-color: var(--info-light-color) !important;
}
.bg-info-lighter {
	background-color: var(--info-lighter-color) !important;
}
.bg-info-dark {
	background-color: var(--info-dark-color) !important;
}
.bg-warning {
	background-color: var(--warning-color) !important;
	color: var(--txt-warning) !important;
}
.bg-warning-gradient {
	background-image: linear-gradient(
		19deg,
		var(--warning-color) 0%,
		var(--warning-light-color) 100%
	) !important;
	color: var(--txt-warning) !important;
}
.bg-warning-light {
	background-color: var(--warning-light-color) !important;
}
.bg-warning-lighter {
	background-color: var(--warning-lighter-color) !important;
}
.bg-warning-dark {
	background-color: var(--warning-dark-color) !important;
}
.bg-danger {
	background-color: var(--danger-color) !important;
	color: var(--txt-danger) !important;
}
.bg-danger-gradient {
	background-image: linear-gradient(
		19deg,
		var(--danger-color) 0%,
		var(--danger-light-color) 100%
	) !important;
	color: var(--txt-warning) !important;
}
.bg-danger-light {
	background-color: var(--danger-light-color) !important;
}
.bg-danger-lighter {
	background-color: var(--danger-lighter-color) !important;
}
.bg-danger-dark {
	background-color: var(--danger-dark-color) !important;
}
.bg-white {
	background-color: white !important;
	color: var(--txt-dark) !important;
}
.bg-transparent {
	background-color: transparent !important;
}
.bg-transparent-half {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-transparent-quarter {
	background-color: rgba(255, 255, 255, 0.25) !important;
}
.bg-transparent-quarter-three {
	background-color: rgba(255, 255, 255, 0.75) !important;
}
.bg-logo-dark {
	background-color: var(--logo-dark-color) !important;
	color: var(--txt-logo-dark) !important;
}
.bg-logo-dark-light {
	background-color: var(--logo-dark-light-color) !important;
}
.bg-logo-dark-lighter {
	background-color: var(--logo-dark-lighter-color) !important;
}
.bg-logo-dark-dark {
	background-color: var(--logo-dark-dark-color) !important;
}
.bg-slate {
	background-color: var(--slate-color) !important;
	color: var(--txt-slate) !important;
}
.bg-slate-light {
	background-color: var(--slate-light-color) !important;
}
.bg-slate-lighter {
	background-color: var(--slate-lighter-color) !important;
}
.bg-slate-dark {
	background-color: var(--slate-dark-color) !important;
}
.bg-ochre {
	background-color: var(--ochre-color) !important;
	color: var(--txt-ochre) !important;
}
.bg-ochre-light {
	background-color: var(--ochre-light-color) !important;
}
.bg-ochre-lighter {
	background-color: var(--ochre-lighter-color) !important;
}
.bg-ochre-dark {
	background-color: var(--ochre-dark-color) !important;
}
.bg-organic {
	background-color: var(--organic-color) !important;
	color: var(--txt-organic) !important;
}
.bg-organic-light {
	background-color: var(--organic-light-color) !important;
}
.bg-organic-lighter {
	background-color: var(--organic-lighter-color) !important;
}
.bg-organic-dark {
	background-color: var(--organic-dark-color) !important;
}
.bg-plum {
	background-color: var(--plum-color) !important;
	color: var(--txt-plum) !important;
}
.bg-plum-light {
	background-color: var(--plum-light-color) !important;
}
.bg-plum-lighter {
	background-color: var(--plum-lighter-color) !important;
}
.bg-plum-dark {
	background-color: var(--plum-dark-color) !important;
}
.bg-mineral {
	background-color: var(--mineral-color) !important;
	color: var(--txt-mineral) !important;
}

.bg-mineral-light {
	background-color: var(--mineral-light-color) !important;
}
.bg-mineral-lighter {
	background-color: var(--mineral-lighter-color) !important;
}
.bg-mineral-dark {
	background-color: var(--mineral-dark-color) !important;
}
/*#endregion*/
/*! BORDERS*/
/*#region borders*/

.boder-large {
	border-width: 4px !important;
}

.border-primary {
	border-color: var(--primary-color) !important;
	border-width: 2px;
	border: solid;
	color: var(--txt-primary) !important;
}
.border-primary-light {
	border-color: var(--primary-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-primary-lighter {
	border-color: var(--primary-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-primary-dark {
	border-color: var(--primary-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-secondary {
	border-color: var(--secondary-color) !important;
	border-width: 2px;
	border: solid;
}
.border-secondary-light {
	border-color: var(--secondary-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-secondary-lighter {
	border-color: var(--secondary-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-secondary-dark {
	border-color: var(--secondary-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-light {
	border-color: var(--light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-light-light {
	border-color: var(--light-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-light-lighter {
	border-color: var(--light-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-light-dark {
	border-color: var(--light-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-dark {
	border-color: var(--dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-dark-light {
	border-color: var(--dark-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-dark-lighter {
	border-color: var(--dark-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-dark-dark {
	border-color: var(--dark-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-pink {
	border-color: var(--pink-color) !important;
	border-width: 2px;
	border: solid;
}
.border-pink-light {
	border-color: var(--pink-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-pink-lighter {
	border-color: var(--pink-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-pink-dark {
	border-color: var(--pink-dark-color) !important;
	border-width: 2px;
}
.border-purple {
	border-color: var(--purple-color) !important;
	border-width: 2px;
	border: solid;
}
.border-purple-light {
	border-color: var(--purple-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-purple-lighter {
	border-color: var(--purple-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-purple-dark {
	border-color: var(--purple-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-success {
	border-color: var(--success-color) !important;
	border-width: 2px;
	border: solid;
}
.border-success-light {
	border-color: var(--success-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-success-lighter {
	border-color: var(--success-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-success-dark {
	border-color: var(--success-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-info {
	border-color: var(--info-color) !important;
	border-width: 2px;
	border: solid;
}
.border-info-light {
	border-color: var(--info-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-info-lighter {
	border-color: var(--info-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-info-dark {
	border-color: var(--info-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-warning {
	border-color: var(--warning-color) !important;
	border-width: 2px;
	border: solid;
}
.border-warning-light {
	border-color: var(--warning-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-warning-lighter {
	border-color: var(--warning-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-warning-dark {
	border-color: var(--warning-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-danger {
	border-color: var(--danger-color) !important;
	border-width: 2px;
	border: solid;
}
.border-danger-light {
	border-color: var(--danger-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-danger-lighter {
	border-color: var(--danger-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-danger-dark {
	border-color: var(--danger-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-white {
	border-color: white !important;
	border-width: 2px;
	border: solid;
}
.border-transparent {
	border-color: transparent !important;
	border-width: 2px;
	border: solid;
}
.border-logo-dark {
	border-color: var(--logo-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-logo-dark-light {
	border-color: var(--logo-dark-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-logo-dark-lighter {
	border-color: var(--logo-dark-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-logo-dark-dark {
	border-color: var(--logo-dark-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-slate {
	border-color: var(--slate-color) !important;
	border-width: 2px;
	border: solid;
}
.border-slate-light {
	border-color: var(--slate-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-slate-lighter {
	border-color: var(--slate-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-slate-dark {
	border-color: var(--slate-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-ochre {
	border-color: var(--ochre-color) !important;
	border-width: 2px;
	border: solid;
}
.border-ochre-light {
	border-color: var(--ochre-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-ochre-lighter {
	border-color: var(--ochre-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-ochre-dark {
	border-color: var(--ochre-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-organic {
	border-color: var(--organic-color) !important;
	border-width: 2px;
	border: solid;
}
.border-organic-light {
	border-color: var(--organic-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-organic-lighter {
	border-color: var(--organic-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-organic-dark {
	border-color: var(--organic-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-plum {
	border-color: var(--plum-color) !important;
	border-width: 2px;
	border: solid;
}
.border-plum-light {
	border-color: var(--plum-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-plum-lighter {
	border-color: var(--plum-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-plum-dark {
	border-color: var(--plum-dark-color) !important;
	border-width: 2px;
	border: solid;
}
.border-mineral {
	border-color: var(--mineral-color) !important;
	border-width: 2px;
	border: solid;
}
.border-mineral-light {
	border-color: var(--mineral-light-color) !important;
	border-width: 2px;
	border: solid;
}
.border-mineral-lighter {
	border-color: var(--mineral-lighter-color) !important;
	border-width: 2px;
	border: solid;
}
.border-mineral-dark {
	border-color: var(--mineral-dark-color) !important;
	border-width: 2px;
	border: solid;
}

/*#endregion*/
/*! COLORS */
/*#region colors*/
.trsp-50 {
	opacity: 0.5;
}
.color-primary {
	color: var(--primary-color) !important;
}
.color-primary-text {
	color: var(--txt-primary) !important;
}
.color-primary-light {
	color: var(--primary-light-color) !important;
}
.color-primary-lighter {
	color: var(--primary-lighter-color) !important;
}
.color-primary-dark {
	color: var(--primary-dark-color) !important;
}
.color-secondary {
	color: var(--secondary-color) !important;
}
.color-secondary-light {
	color: var(--secondary-light-color) !important;
}
.color-secondary-lighter {
	color: var(--secondary-lighter-color) !important;
}
.color-secondary-dark {
	color: var(--secondary-dark-color) !important;
}
.color-light {
	color: var(--light-color) !important;
}
.color-light-light {
	color: var(--light-light-color) !important;
}
.color-light-lighter {
	color: var(--light-lighter-color) !important;
}
.color-light-dark {
	color: var(--light-dark-color) !important;
}
.color-dark {
	color: var(--dark-color) !important;
}
.color-dark-light {
	color: var(--dark-light-color) !important;
}
.color-dark-lighter {
	color: var(--dark-lighter-color) !important;
}
.color-dark-dark {
	color: var(--dark-dark-color) !important;
}
.color-pink {
	color: var(--pink-color) !important;
}
.color-pink-light {
	color: var(--pink-light-color) !important;
}
.color-pink-lighter {
	color: var(--pink-lighter-color) !important;
}
.color-pink-dark {
	color: var(--pink-dark-color) !important;
}
.color-purple {
	color: var(--purple-color) !important;
}
.color-purple-light {
	color: var(--purple-light-color) !important;
}
.color-purple-lighter {
	color: var(--purple-lighter-color) !important;
}
.color-purple-dark {
	color: var(--purple-dark-color) !important;
}
.color-success {
	color: var(--success-color) !important;
}
.color-success-light {
	color: var(--success-light-color) !important;
}
.color-success-lighter {
	color: var(--success-lighter-color) !important;
}
.color-success-dark {
	color: var(--success-dark-color) !important;
}
.color-info {
	color: var(--info-color) !important;
}
.color-info-light {
	color: var(--info-light-color) !important;
}
.color-info-lighter {
	color: var(--info-lighter-color) !important;
}
.color-info-dark {
	color: var(--info-dark-color) !important;
}
.color-warning {
	color: var(--warning-color) !important;
}
.color-warning-light {
	color: var(--warning-light-color) !important;
}
.color-warning-lighter {
	color: var(--warning-lighter-color) !important;
}
.color-warning-dark {
	color: var(--warning-dark-color) !important;
}
.color-danger {
	color: var(--danger-color) !important;
}
.color-danger-light {
	color: var(--danger-light-color) !important;
}
.color-danger-lighter {
	color: var(--danger-lighter-color) !important;
}
.color-danger-dark {
	color: var(--danger-dark-color) !important;
}
.color-white {
	color: white !important;
}
.color-transparent {
	color: transparent !important;
}
.color-logo-dark {
	color: var(--logo-dark-color) !important;
}
.color-logo-dark-light {
	color: var(--logo-dark-light-color) !important;
}
.color-logo-dark-lighter {
	color: var(--logo-dark-lighter-color) !important;
}
.color-logo-dark-dark {
	color: var(--logo-dark-dark-color) !important;
}
.color-slate {
	color: var(--slate-color) !important;
}
.color-slate-light {
	color: var(--slate-light-color) !important;
}
.color-slate-lighter {
	color: var(--slate-lighter-color) !important;
}
.color-slate-dark {
	color: var(--slate-dark-color) !important;
}
.color-ochre {
	color: var(--ochre-color) !important;
}
.color-ochre-light {
	color: var(--ochre-light-color) !important;
}
.color-ochre-lighter {
	color: var(--ochre-lighter-color) !important;
}
.color-ochre-dark {
	color: var(--ochre-dark-color) !important;
}
.color-organic {
	color: var(--organic-color) !important;
}
.color-organic-light {
	color: var(--organic-light-color) !important;
}
.color-organic-lighter {
	color: var(--organic-lighter-color) !important;
}
.color-organic-dark {
	color: var(--organic-dark-color) !important;
}
.color-plum {
	color: var(--plum-color) !important;
}
.color-plum-light {
	color: var(--plum-light-color) !important;
}
.color-plum-lighter {
	color: var(--plum-lighter-color) !important;
}
.color-plum-dark {
	color: var(--plum-dark-color) !important;
}
.color-mineral {
	color: var(--mineral-color) !important;
}
.color-mineral-light {
	color: var(--mineral-light-color) !important;
}
.color-mineral-lighter {
	color: var(--mineral-lighter-color) !important;
}
.color-mineral-dark {
	color: var(--mineral-dark-color) !important;
}

/*#endregion*/
/*! BUTTONS */
/*#region buttons*/

.btn-circle {
	width: 30px !important;
	height: 30px !important;
	text-align: center !important;
	padding: 6px 0 !important;
	font-size: 12px !important;
	line-height: 1.428571429 !important;
	border-radius: 15px !important;
}

.btn-circle.btn-lg {
	width: 50px !important;
	height: 50px !important;
	padding: 10px 16px !important;
	font-size: 18px !important;
	line-height: 1.33 !important;
	border-radius: 25px !important;
}

.btn-circle.btn-xl {
	width: 70px !important;
	height: 70px !important;
	padding: 10px 16px !important;
	font-size: 24px !important;
	line-height: 1.33 !important;
	border-radius: 35px !important;
}

/* use negative margins to accommodate wider button text */

.btn-circle span {
	margin: 0 -2rem;
}
/*Add animation grow to btn hover*/
.btn:hover {
	transform: scale(1.02);
	transition: transform 0.5s;
}
.btn:active {
	transform: scale(0.98);
	transition: transform 0.5s;
}

.btn-round {
	border-radius: 100px !important;
}
.btn-sm-height {
	height: 30px !important;
}
.btn-md-height {
	height: 40px !important;
}

.btn-index {
	width: 200px !important;
}
.btn-primary {
	background-color: var(--primary-color) !important;
	color: var(--light-color) !important;
	border-color: var(--primary-color) !important;
}
.btn-primary-light {
	background-color: var(--primary-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--primary-light-color) !important;
}
.btn-primary-lighter {
	background-color: var(--primary-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--primary-lighter-color) !important;
}
.btn-primary-dark {
	background-color: var(--primary-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--primary-dark-color) !important;
}
.btn-secondary {
	background-color: var(--secondary-color) !important;
	color: var(--light-color) !important;
	border-color: var(--secondary-color) !important;
}
.btn-secondary-light {
	background-color: var(--secondary-light-color) !important;
	color: var(--dark-color) !important;
	border-color: var(--secondary-light-color) !important;
}
.btn-secondary-lighter {
	background-color: var(--secondary-lighter-color) !important;
	color: var(--dark-color) !important;
	border-color: var(--secondary-lighter-color) !important;
}
.btn-secondary-dark {
	background-color: var(--secondary-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--secondary-dark-color) !important;
}
.btn-light {
	background-color: var(--light-color) !important;
	color: var(--dark-color) !important;
	border-color: var(--light-color) !important;
}
.btn-light-light {
	background-color: var(--light-light-color) !important;
	color: var(--dark-color) !important;
	border-color: var(--light-light-color) !important;
}
.btn-light-lighter {
	background-color: var(--light-lighter-color) !important;
	color: var(--dark-color) !important;
	border-color: var(--light-lighter-color) !important;
}
.btn-light-dark {
	background-color: var(--light-dark-color) !important;
	color: var(--dark-color) !important;
	border-color: var(--light-dark-color) !important;
}
.btn-dark {
	background-color: var(--dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--dark-color) !important;
}
.btn-dark-light {
	background-color: var(--dark-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--dark-light-color) !important;
}
.btn-dark-lighter {
	background-color: var(--dark-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--dark-lighter-color) !important;
}
.btn-dark-dark {
	background-color: var(--dark-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--dark-dark-color) !important;
}
.btn-pink {
	background-color: var(--pink-color) !important;
	color: var(--light-color) !important;
	border-color: var(--pink-color) !important;
}
.btn-pink-light {
	background-color: var(--pink-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--pink-light-color) !important;
}
.btn-pink-lighter {
	background-color: var(--pink-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--pink-lighter-color) !important;
}
.btn-pink-dark {
	background-color: var(--pink-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--pink-dark-color) !important;
}
.btn-purple {
	background-color: var(--purple-color) !important;
	color: var(--light-color) !important;
	border-color: var(--purple-color) !important;
}
.btn-purple-light {
	background-color: var(--purple-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--purple-light-color) !important;
}
.btn-purple-lighter {
	background-color: var(--purple-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--purple-lighter-color) !important;
}
.btn-purple-dark {
	background-color: var(--purple-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--purple-dark-color) !important;
}
.btn-success {
	background-color: var(--success-color) !important;
	color: var(--light-color) !important;
	border-color: var(--success-color) !important;
}
.btn-success-light {
	background-color: var(--success-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--success-light-color) !important;
}
.btn-success-lighter {
	background-color: var(--success-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--success-lighter-color) !important;
}
.btn-success-dark {
	background-color: var(--success-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--success-dark-color) !important;
}
.btn-info {
	background-color: var(--info-color) !important;
	color: var(--light-color) !important;
	border-color: var(--info-color) !important;
}
.btn-info-light {
	background-color: var(--info-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--info-light-color) !important;
}
.btn-info-lighter {
	background-color: var(--info-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--info-lighter-color) !important;
}
.btn-info-dark {
	background-color: var(--info-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--info-dark-color) !important;
}
.btn-warning {
	background-color: var(--warning-color) !important;
	color: var(--light-color) !important;
	border-color: var(--warning-color) !important;
}
.btn-warning-light {
	background-color: var(--warning-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--warning-light-color) !important;
}
.btn-warning-lighter {
	background-color: var(--warning-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--warning-lighter-color) !important;
}
.btn-warning-dark {
	background-color: var(--warning-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--warning-dark-color) !important;
}
.btn-danger {
	background-color: var(--danger-color) !important;
	color: var(--light-color) !important;
	border-color: var(--danger-color) !important;
}
.btn-danger-light {
	background-color: var(--danger-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--danger-light-color) !important;
}
.btn-danger-lighter {
	background-color: var(--danger-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--danger-lighter-color) !important;
}
.btn-danger-dark {
	background-color: var(--danger-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--danger-dark-color) !important;
}
.btn-white {
	background-color: white !important;
	color: var(--dark-color) !important;
	border-color: white !important;
}
.btn-transparent {
	background-color: transparent !important;
	color: var(--dark-color) !important;
	border-color: transparent !important;
}
.btn-logo-dark {
	background-color: var(--logo-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--logo-dark-color) !important;
}
.btn-logo-dark-light {
	background-color: var(--logo-dark-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--logo-dark-light-color) !important;
}
.btn-logo-dark-lighter {
	background-color: var(--logo-dark-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--logo-dark-lighter-color) !important;
}
.btn-logo-dark-dark {
	background-color: var(--logo-dark-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--logo-dark-dark-color) !important;
}
.btn-slate {
	background-color: var(--slate-color) !important;
	color: var(--light-color) !important;
	border-color: var(--slate-color) !important;
}
.btn-slate-light {
	background-color: var(--slate-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--slate-light-color) !important;
}
.btn-slate-lighter {
	background-color: var(--slate-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--slate-lighter-color) !important;
}
.btn-slate-dark {
	background-color: var(--slate-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--slate-dark-color) !important;
}
.btn-ochre {
	background-color: var(--ochre-color) !important;
	color: var(--light-color) !important;
	border-color: var(--ochre-color) !important;
}
.btn-ochre-light {
	background-color: var(--ochre-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--ochre-light-color) !important;
}
.btn-ochre-lighter {
	background-color: var(--ochre-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--ochre-lighter-color) !important;
}
.btn-ochre-dark {
	background-color: var(--ochre-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--ochre-dark-color) !important;
}
.btn-organic {
	background-color: var(--organic-color) !important;
	color: var(--light-color) !important;
	border-color: var(--organic-color) !important;
}
.btn-organic-light {
	background-color: var(--organic-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--organic-light-color) !important;
}
.btn-organic-lighter {
	background-color: var(--organic-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--organic-lighter-color) !important;
}
.btn-organic-dark {
	background-color: var(--organic-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--organic-dark-color) !important;
}
.btn-plum {
	background-color: var(--plum-color) !important;
	color: var(--light-color) !important;
	border-color: var(--plum-color) !important;
}
.btn-plum-light {
	background-color: var(--plum-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--plum-light-color) !important;
}
.btn-plum-lighter {
	background-color: var(--plum-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--plum-lighter-color) !important;
}
.btn-plum-dark {
	background-color: var(--plum-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--plum-dark-color) !important;
}
.btn-mineral {
	background-color: var(--mineral-color) !important;
	color: var(--light-color) !important;
	border-color: var(--mineral-color) !important;
}
.btn-mineral-light {
	background-color: var(--mineral-light-color) !important;
	color: var(--light-color) !important;
	border-color: var(--mineral-light-color) !important;
}
.btn-mineral-lighter {
	background-color: var(--mineral-lighter-color) !important;
	color: var(--light-color) !important;
	border-color: var(--mineral-lighter-color) !important;
}
.btn-mineral-dark {
	background-color: var(--mineral-dark-color) !important;
	color: var(--light-color) !important;
	border-color: var(--mineral-dark-color) !important;
}
/*#endregion*/
/*#region Btn Outline*/
.btn-outline-primary {
	--bs-btn-color: var(--primary-color) !important;
	--bs-btn-border-color: var(--primary-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--primary-color);
	--bs-btn-hover-border-color: var(--primary-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--primary-color);
	--bs-btn-active-border-color: var(--primary-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--primary-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--primary-color);
	--bs-gradient: none;
}
.btn-outline-primary-light {
	--bs-btn-color: var(--primary-light-color) !important;
	--bs-btn-border-color: var(--primary-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--primary-light-color);
	--bs-btn-hover-border-color: var(--primary-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--primary-light-color);
	--bs-btn-active-border-color: var(--primary-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--primary-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--primary-light-color);
	--bs-gradient: none;
}
.btn-outline-primary-no-focus {
	background-color: transparent !important;
	color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}
.btn-outline-primary-no-focus.active {
	background-color: var(--primary-color) !important;
	color: var(--light-color) !important;
	border-color: var(--primary-color) !important;
}

.btn-outline-secondary {
	--bs-btn-color: var(--secondary-color) !important;
	--bs-btn-border-color: var(--secondary-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--secondary-color);
	--bs-btn-hover-border-color: var(--secondary-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--secondary-color);
	--bs-btn-active-border-color: var(--secondary-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--secondary-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--secondary-color);
	--bs-gradient: none;
}
.btn-outline-secondary-light {
	--bs-btn-color: var(--secondary-light-color) !important;
	--bs-btn-border-color: var(--secondary-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--secondary-light-color);
	--bs-btn-hover-border-color: var(--secondary-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--secondary-light-color);
	--bs-btn-active-border-color: var(--secondary-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--secondary-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--secondary-light-color);
	--bs-gradient: none;
}

.btn-outline-light {
	--bs-btn-color: var(--light-color) !important;
	--bs-btn-border-color: var(--light-color) !important;
	--bs-btn-hover-color: var(--dark-color);
	--bs-btn-hover-bg: var(--light-color);
	--bs-btn-hover-border-color: var(--light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--dark-color);
	--bs-btn-active-bg: var(--light-color);
	--bs-btn-active-border-color: var(--light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--light-color);
	--bs-gradient: none;
}
.btn-outline-light-light {
	--bs-btn-color: var(--light-light-color) !important;
	--bs-btn-border-color: var(--light-light-color) !important;
	--bs-btn-hover-color: var(--dark-color);
	--bs-btn-hover-bg: var(--light-light-color);
	--bs-btn-hover-border-color: var(--light-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--dark-color);
	--bs-btn-active-bg: var(--light-light-color);
	--bs-btn-active-border-color: var(--light-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--light-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--light-light-color);
	--bs-gradient: none;
}

.btn-outline-dark {
	--bs-btn-color: var(--dark-color) !important;
	--bs-btn-border-color: var(--dark-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--dark-color);
	--bs-btn-hover-border-color: var(--dark-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--dark-color);
	--bs-btn-active-border-color: var(--dark-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--dark-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--dark-color);
	--bs-gradient: none;
}
.btn-outline-dark-light {
	--bs-btn-color: var(--dark-light-color) !important;
	--bs-btn-border-color: var(--dark-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--dark-light-color);
	--bs-btn-hover-border-color: var(--dark-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--dark-light-color);
	--bs-btn-active-border-color: var(--dark-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--dark-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--dark-light-color);
	--bs-gradient: none;
}

.btn-outline-pink {
	--bs-btn-color: var(--pink-color) !important;
	--bs-btn-border-color: var(--pink-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--pink-color);
	--bs-btn-hover-border-color: var(--pink-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--pink-color);
	--bs-btn-active-border-color: var(--pink-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--pink-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--pink-color);
	--bs-gradient: none;
}
.btn-outline-pink-light {
	--bs-btn-color: var(--pink-light-color) !important;
	--bs-btn-border-color: var(--pink-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--pink-light-color);
	--bs-btn-hover-border-color: var(--pink-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--pink-light-color);
	--bs-btn-active-border-color: var(--pink-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--pink-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--pink-light-color);
	--bs-gradient: none;
}

.btn-outline-purple {
	--bs-btn-color: var(--purple-color) !important;
	--bs-btn-border-color: var(--purple-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--purple-color);
	--bs-btn-hover-border-color: var(--purple-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--purple-color);
	--bs-btn-active-border-color: var(--purple-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--purple-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--purple-color);
	--bs-gradient: none;
}
.btn-outline-purple-light {
	--bs-btn-color: var(--purple-light-color) !important;
	--bs-btn-border-color: var(--purple-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--purple-light-color);
	--bs-btn-hover-border-color: var(--purple-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--purple-light-color);
	--bs-btn-active-border-color: var(--purple-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--purple-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--purple-light-color);
	--bs-gradient: none;
}

.btn-outline-success {
	--bs-btn-color: var(--success-color) !important;
	--bs-btn-border-color: var(--success-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--success-color);
	--bs-btn-hover-border-color: var(--success-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--success-color);
	--bs-btn-active-border-color: var(--success-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--success-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--success-color);
	--bs-gradient: none;
}
.btn-outline-success-light {
	--bs-btn-color: var(--success-light-color) !important;
	--bs-btn-border-color: var(--success-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--success-light-color);
	--bs-btn-hover-border-color: var(--success-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--success-light-color);
	--bs-btn-active-border-color: var(--success-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--success-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--success-light-color);
	--bs-gradient: none;
}

.btn-outline-info {
	--bs-btn-color: var(--info-color) !important;
	--bs-btn-border-color: var(--info-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--info-color);
	--bs-btn-hover-border-color: var(--info-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--info-color);
	--bs-btn-active-border-color: var(--info-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--info-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--info-color);
	--bs-gradient: none;
}
.btn-outline-info-light {
	--bs-btn-color: var(--info-light-color) !important;
	--bs-btn-border-color: var(--info-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--info-light-color);
	--bs-btn-hover-border-color: var(--info-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--info-light-color);
	--bs-btn-active-border-color: var(--info-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--info-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--info-light-color);
	--bs-gradient: none;
}

.btn-outline-warning {
	--bs-btn-color: var(--warning-color) !important;
	--bs-btn-border-color: var(--warning-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--warning-color);
	--bs-btn-hover-border-color: var(--warning-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--warning-color);
	--bs-btn-active-border-color: var(--warning-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--warning-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--warning-color);
	--bs-gradient: none;
}
.btn-outline-warning-light {
	--bs-btn-color: var(--warning-light-color) !important;
	--bs-btn-border-color: var(--warning-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--warning-light-color);
	--bs-btn-hover-border-color: var(--warning-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--warning-light-color);
	--bs-btn-active-border-color: var(--warning-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--warning-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--warning-light-color);
	--bs-gradient: none;
}

.btn-outline-danger {
	--bs-btn-color: var(--danger-color) !important;
	--bs-btn-border-color: var(--danger-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--danger-color);
	--bs-btn-hover-border-color: var(--danger-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--danger-color);
	--bs-btn-active-border-color: var(--danger-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--danger-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--danger-color);
	--bs-gradient: none;
}
.btn-outline-danger-light {
	--bs-btn-color: var(--danger-light-color) !important;
	--bs-btn-border-color: var(--danger-light-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--danger-light-color);
	--bs-btn-hover-border-color: var(--danger-light-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--danger-light-color);
	--bs-btn-active-border-color: var(--danger-light-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--danger-light-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--danger-light-color);
	--bs-gradient: none;
}

/*btn-outline-logo-dark*/
.btn-outline-logo-dark {
	--bs-btn-color: var(--logo-dark-color) !important;
	--bs-btn-border-color: var(--logo-dark-color) !important;
	--bs-btn-hover-color: var(--dark-color) !important;
	--bs-btn-hover-bg: var(--logo-dark-color) !important;
	--bs-btn-hover-border-color: var(--logo-dark-color) !important;
	--bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
	--bs-btn-active-color: var(--dark-color) !important;
	--bs-btn-active-bg: var(--logo-dark-color) !important;
	--bs-btn-active-border-color: var(--logo-dark-color) !important;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	--bs-btn-disabled-color: var(--logo-dark-color) !important;
	--bs-btn-disabled-bg: transparent !important;
	--bs-btn-disabled-border-color: var(--logo-dark-color) !important;
	--bs-gradient: none;
}
/*btn-outline-ochre*/
.btn-outline-ochre {
	--bs-btn-color: var(--ochre-color) !important;
	--bs-btn-border-color: var(--ochre-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--ochre-color);
	--bs-btn-hover-border-color: var(--ochre-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--ochre-color);
	--bs-btn-active-border-color: var(--ochre-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--ochre-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--ochre-color);
	--bs-gradient: none;
}
/*btn-outline-slate*/
.btn-outline-slate {
	--bs-btn-color: var(--slate-color) !important;
	--bs-btn-border-color: var(--slate-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--slate-color);
	--bs-btn-hover-border-color: var(--slate-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--slate-color);
	--bs-btn-active-border-color: var(--slate-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--slate-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--slate-color);
	--bs-gradient: none;
}
/*btn-outline-organic*/
.btn-outline-organic {
	--bs-btn-color: var(--organic-color) !important;
	--bs-btn-border-color: var(--organic-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--organic-color);
	--bs-btn-hover-border-color: var(--organic-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--organic-color);
	--bs-btn-active-border-color: var(--organic-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--organic-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--organic-color);
	--bs-gradient: none;
}
/*btn-outline-plum*/
.btn-outline-plum {
	--bs-btn-color: var(--plum-color) !important;
	--bs-btn-border-color: var(--plum-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--plum-color);
	--bs-btn-hover-border-color: var(--plum-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--plum-color);
	--bs-btn-active-border-color: var(--plum-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--plum-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--plum-color);
	--bs-gradient: none;
}
/*btn-outline-mineral*/
.btn-outline-mineral {
	--bs-btn-color: var(--mineral-color) !important;
	--bs-btn-border-color: var(--mineral-color) !important;
	--bs-btn-hover-color: var(--light-color);
	--bs-btn-hover-bg: var(--mineral-color);
	--bs-btn-hover-border-color: var(--mineral-color);
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: var(--light-color);
	--bs-btn-active-bg: var(--mineral-color);
	--bs-btn-active-border-color: var(--mineral-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--mineral-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--mineral-color);
	--bs-gradient: none;
}

/*#endregion*/
/*! NAV */
/*#region Navbar*/

.nav-lab {
	min-height: 120px;
}

.nav-height-100 {
	height: 95px;
	max-height: 95px;
}
@media screen and (max-width: 700px) {
	.nav-height-100 {
		height: 70px;
		max-height: 70px;
	}
}

.dropdown a:hover {
	background-color: var(--secondary-color) !important;
	color: var(--txt-secondary) !important;
	border-radius: 6px;
}
.dropdown a:active {
	background-color: var(--secondary-color) !important;
	color: var(--txt-secondary) !important;
	border-radius: 6px;
}
.dropdown a:focus {
	background-color: var(--secondary-color) !important;
	color: var(--txt-secondary) !important;
	border-radius: 6px;
}
.dropdown-item {
	/*Wrap text*/
	word-wrap: break-word;
	text-wrap: wrap;
}
/*#endregion*/
/*! SPINNERS */
/*#region SPINNERS*/
.primary-spinner {
	border: 10px solid var(--primary-color);
	border-top: 10px solid var(--primary-light-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.primary-spinner-sm {
	border: 3px solid var(--primary-color);
	border-top: 3px solid var(--primary-light-color);
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 2s linear infinite;
}
.secondary-spinner {
	border: 10px solid var(--secondary-light-color);
	border-top: 10px solid var(--secondary-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.warning-spinner {
	border: 10px solid var(--warning-light-color);
	border-top: 10px solid var(--warning-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.danger-spinner {
	border: 10px solid var(--danger-light-color);
	border-top: 10px solid var(--danger-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}

.success-spinner {
	border: 10px solid var(--success-light-color);
	border-top: 10px solid var(--success-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.purple-spinner {
	border: 10px solid var(--purple-light-color);
	border-top: 10px solid var(--purple-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.info-spinner {
	border: 10px solid var(--info-light-color);
	border-top: 10px solid var(--info-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.info-spinner-sm {
	border: 5px solid var(--info-light-color);
	border-top: 5px solid var(--info-color);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: spin 2s linear infinite;
}
.pink-spinner {
	border: 10px solid var(--pink-light-color);
	border-top: 10px solid var(--pink-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.ochre-spinner {
	border: 10px solid var(--ochre-light-color);
	border-top: 10px solid var(--ochre-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.organic-spinner {
	border: 10px solid var(--organic-light-color);
	border-top: 10px solid var(--organic-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.slate-spinner {
	border: 10px solid var(--slate-light-color);
	border-top: 10px solid var(--slate-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.plum-spinner {
	border: 10px solid var(--plum-light-color);
	border-top: 10px solid var(--plum-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.mineral-spinner {
	border: 10px solid var(--mineral-light-color);
	border-top: 10px solid var(--mineral-color);
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}
.spin-border-s {
	border-width: 4px;
}
.spin-border-m {
	border-width: 6px;
}
.spin-border-l {
	border-width: 8px;
}
.spin-border-xl {
	border-width: 10px;
}

.parent-spinner-superpose {
	position: relative;
	width: 160px;
	height: 160px;
	margin: 10px;
}

.child-spinner-superpose1 {
	position: absolute;
	width: 160px;
	height: 160px;
	top: 0;
	left: 0;
}

.child-spinner-superpose2 {
	z-index: 1;
	width: 160px;
	height: 160px;
	border-width: 2px;
}

.parent-superpose {
	position: relative;
	margin: 10px;
}

.child-superpose1 {
	position: absolute;
	top: 0;
	left: 0;
}

.child-superpose2 {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/*#endregion*/
/* ! CARDS*/
/*#region card*/

.card-body border-rounded {
	background-color: var(--background);
}
.p-dialog-content {
	background-color: var(--background);
}
.card-login {
	width: 30rem;
}

.card-no-padding-margin {
	padding: 0px !important;
	margin: 0px !important;
}

.cards-no-border {
	border: none !important;
	/*Add shadow bottom right no top*/
	/*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);*/
}
.card-sm {
	height: 54px !important;
	max-height: 54px !important;
	overflow: hidden;
}

.card-promoted-swiper {
	width: 33% !important;
	height: 300px !important;
}
.card-promoted {
	width: 95% !important;
	height: 100% !important;
}
.card-product {
	max-width: 100% !important;
}

/* Media mobile*/
@media (max-width: 700px) {
	.card-login {
		width: 90%;
	}

	.card-promoted-swiper {
		min-width: 100% !important;
		width: 100% !important;
		height: 200px !important;
	}
}

@media screen and (min-width: 701px) and (max-width: 768px) {
	.card-promoted-swiper {
		min-width: 50% !important;
		width: 50% !important;
		height: 200px !important;
	}
}
/*#endregion*/
/* ! IMG*/
/*#region images*/
.img-full-width {
	width: 100%;
	height: auto;
	object-fit: cover;
}
.img-xs {
	width: 30px !important;
	height: 30px !important;
	object-fit: cover;
}
.img-xs-responsive {
	width: auto;
	height: 30px;
	/* keep aspect ratio*/
	object-fit: cover;
}
.img-sm {
	width: 50px !important;
	height: 50px !important;
	object-fit: cover;
}
.img-sm-responsive {
	height: 50px;
	width: auto;
	/* keep aspect ratio*/
	object-fit: cover;
}
.img-s {
	width: 80px !important;
	height: 80px !important;
	object-fit: cover;
}
.img-s-responsive {
	height: 80px;
	max-height: 80px;
	width: auto;
	/* keep aspect ratio*/
	object-fit: cover;
}
.img-responsive {
	width: auto;
	height: 100%;
	/* keep aspect ratio*/
	object-fit: cover;
}

.img-md {
	width: 100px !important;
	height: 100px !important;
	object-fit: cover;
}
.img-md-responsive {
	height: 100px;
	max-height: 100px;
	width: auto;
	/* keep aspect ratio*/
	object-fit: cover;
}
.img-lg {
	width: 150px !important;
	height: 150px !important;
	object-fit: cover;
}
.img-lg-responsive {
	width: 100% !important;
	height: 150px;
	/* keep aspect ratio*/
	object-fit: cover;
}
.img-lg-responsive-logo {
	width: auto !important;
	height: 150px;
	/* keep aspect ratio*/
	object-fit: cover;
}
.img-xl {
	width: 200px !important;
	height: 200px !important;
	object-fit: cover;
}
.img-xl-bordered {
	width: 210px !important;
	height: 210px !important;
	object-fit: cover;
	padding: 5px;
}
.img-xl-responsive {
	width: 100% !important;
	height: 250px;
	/* keep aspect ratio*/
	object-fit: cover;
}
.img-xxl {
	width: 300px !important;
	height: 300px !important;
	object-fit: cover;
}
.img-xxl-responsive {
	width: 100% !important;
	height: 350px;
	/* keep aspect ratio*/
	object-fit: cover;
}
/*Mobile devices*/
@media only screen and (max-width: 700px) {
	.img-s {
		width: 50px !important;
		height: 50px !important;
		object-fit: cover;
	}
	.img-s-responsive {
		height: 50px;
		max-height: 50px;
		width: auto;
		/* keep aspect ratio*/
		object-fit: cover;
	}
	.img-xl-responsive {
		width: 100% !important;
		height: 250px;
		/* keep aspect ratio*/
		object-fit: cover;
	}
	.img-lg-responsive {
		width: 100% !important;
		max-width: 350px;
		min-height: 180px;
		/* keep aspect ratio*/
		object-fit: cover;
	}
}

@media screen and (min-width: 701px) and (max-width: 768px) {
	.img-lg-responsive {
		width: 100% !important;
		max-width: 350px;
		min-height: 250px;
		/* keep aspect ratio*/
		object-fit: cover;
	}
}

.top-bot-img-index {
	margin: 1px;
}
.lmvs-top-bot-img1 {
	width: 100px;
	height: 100px;
	border-radius: 100px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
	border: 3px solid var(--success-color);
	background-color: var(--light-color);
	padding: 2px;
}
.lmvs-top-bot-img2 {
	width: 100px;
	height: 100px;
	border-radius: 100px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
	border: 3px solid var(--purple-color);
	background-color: var(--light-color);
	padding: 2px;
}
.promptlab-top-bot-img {
	width: 100px;
	height: 100px;
	border-radius: 100px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
	border: 3px solid var(--info-color);
	background-color: var(--light-color);
	padding: 2px;
}
.left-inline-card-img {
	border-radius: 5px 0px 0px 5px;
}
.top-left-inline-card-img {
	border-radius: 5px 0px 0px 0px;
}
.right-inline-card-img {
	border-radius: 0px 5px 5px 0px;
}
/*#endregion*/
/*! CONTAINERS*/
/*#region containers */
.cookie-consent-modal {
	z-index: 1031 !important;
}
.container-inline {
	display: flex !important;
	justify-content: left !important;
	align-items: center !important;
}
.container-inline-spaced {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
}
.container-inline-spaced-full-h {
	display: flex !important;
	justify-content: space-between !important;
	align-items: stretch !important;
}
.container-inline-right {
	display: flex !important;
	justify-content: right !important;
	align-items: center !important;
}
.container-inline-centered {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
/*! CONTAINERS */
.cookie-consent-modal {
	z-index: 1031 !important;
}
.container-warning-light-rounded {
	background-color: var(--warning-light-color);
	color: var(--dark-color);
	border-radius: 10px;
	padding: 10px;
}
.horizontal-scroller {
	width: 100%;
	overflow-x: scroll;
	white-space: nowrap;
	margin: 0px;
	padding: 5px;
}
.horizontal-scroller-right {
	width: 100%;
	overflow-x: auto;
	white-space: nowrap;
	/*direction right*/
	direction: rtl;
}
.horizontal-scroller-right button {
	direction: ltr;
}
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	border-radius: 20px;
}
/* Track */
::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.025);
	border-radius: 20px;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.06);
	border-radius: 20px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.09);
	border-radius: 20px;
}
.scroll-anchor-first {
	scroll-margin-top: 400px;
}
.scroll-anchor {
	scroll-margin-top: 180px;
}
.scroll-bottom-anchor {
	scroll-margin-bottom: 2000px;
}
.sticky-top-header {
	position: -webkit-sticky;
	position: sticky;
	top: 100px;
	padding: 0 0 0 0;
	z-index: 1019;
	background-color: var(--slate-color) !important;
	color: var(--light-color);
}
.hilightbg-sticky-top-header {
	position: -webkit-sticky;
	position: sticky;
	top: 100px;
	padding: 5px 0 0 0;
	margin-left: 1px;
	margin-right: 1px;
	z-index: 1019;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.bottom-rounded {
	border-radius: 0px 0px 10px 10px;
}
.container-full {
	width: 100%;
	overflow-x: hidden;
}
.container {
	width: 100% !important;
	margin: 10px !important;
}
.content-container {
	margin-top: 120px !important;
	min-height: 90vh;
	max-width: 1800px;
	margin-left: auto !important;
	margin-right: auto !important;
}

.sticky-top-card-header {
	z-index: 1019;
	padding: 0 0 0 0;
}

.sticky-accordion-header {
	z-index: 1019;
	top: 95px;
	position: -webkit-sticky;
	position: sticky;
}
.sticky-edit-btn-header {
	z-index: 1018;
	top: 140px;
	position: -webkit-sticky;
	position: sticky;
}
.sticky-edit-btn-single-header {
	z-index: 1018;
	top: 140px;
	position: -webkit-sticky;
	position: sticky;
}
.sticky-table-header {
	z-index: 1017;
	top: 175px;
	position: -webkit-sticky;
	position: sticky;
}

@media only screen and (max-width: 700px) {
	.content-container {
		margin-top: 80px !important;
	}
	.sticky-top-header {
		top: 70px;
	}
	.hilightbg-sticky-top-header {
		top: 70px;
	}
	.sticky-top-card-header {
		top: 123px;
		position: -webkit-sticky;
		position: sticky;
	}
	.sticky-accordion-header {
		top: 95px;
	}
	.sticky-edit-btn-header {
		top: 140px;
	}
	.sticky-table-header {
		top: 180px;
	}
	.sticky-edit-btn-single-header {
		top: 140px;
	}
}
@media screen and (min-width: 701px) and (max-width: 768px) {
	.content-container {
		margin-top: 100px !important;
	}
	.sticky-top-header {
		top: 100px;
	}
	.hilightbg-sticky-top-header {
		top: 100px;
	}
	.sticky-top-card-header {
		top: 150px;
		position: -webkit-sticky;
		position: sticky;
	}
	.sticky-accordion-header {
		top: 95px;
	}
	.sticky-edit-btn-header {
		top: 150px;
	}
	.sticky-table-header {
		top: 180px;
	}
	.sticky-edit-btn-single-header {
		top: 140px;
	}
}
/*! FOOTER*/
.footer-bottom {
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.4);
}
.footer-bottom {
	position: fixed;
	bottom: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 40px !important;
	box-shadow: 0 0 2px 2px rgba(var(--primary-color), 0.2);
	z-index: 2000;
}
.footer-bottom-card {
	position: relative;
	bottom: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 40px !important;
	box-shadow: 0 0 2px 2px rgba(var(--primary-color), 0.2);
	z-index: 2000;
}
.footer-bottom-xl {
	position: fixed;
	bottom: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 80px !important;
	box-shadow: 0 0 2px 2px rgba(var(--primary-color), 0.2);
	z-index: 2000;
}
.footer-hidden-bottom {
	bottom: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 40px !important;
	box-shadow: 0 -2px 2px rgba(var(--primary-color), 0.2);
	/*margin-top: 15px;*/
	z-index: 2000;
}
.modal-footer-text {
	text-align: center;
	vertical-align: middle;
	height: 40px !important;
}

.footer-back-zindex {
	z-index: 50 !important;
	bottom: 0 !important;
}
/*#endregion*/
/*! LAYOUTS */
/*#region layouts*/
.dt-layout-row {
	display: flex !important;
	justify-content: end !important;
	align-items: end !important;
	margin: 4px;
}
/*#endregion*/
/*! PARALLAX*/
/*#region parallax*/
.parent {
	position: relative;
	width: 90%;
	margin: 10px;
}
.head-parent {
	height: 100px !important;
}
.head-parent-img {
	height: 100%;
	width: 100%;
	/*Image cover*/
	object-fit: cover;
}
.border-rounded {
	border-radius: 6px !important;
}

.border-rounded-top {
	border-radius: 6px 6px 0px 0px !important;
}

.child1 {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.child2 {
	z-index: 1;
	width: 100%;
	height: 100%;
	margin: auto;
}
/*#endregion*/
/*! CAROUSEL*/
/*#region carousel*/
.carousel-content {
	background-color: rgba(0, 0, 0, 0.6) !important;
}

.carousel-content-sm {
	background-color: rgba(0, 0, 0, 0.6) !important;
	bottom: 30px !important;
}

.carousel-content-inner {
	margin: 0 5px !important;
}
.carousel-indicators-bottom {
	/**/
	bottom: 1px;
}

.carousel-content-full {
	background-color: rgba(0, 0, 0, 0.5) !important;
	bottom: 0 !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	margin-bottom: 0 !important;
}
.carousel-indicators-bottom-full {
	bottom: 0 !important;
	margin-bottom: 0 !important;
}

@media only screen and (max-width: 700px) {
	.carousel-content {
		background-color: rgba(0, 0, 0, 0.5) !important;
		bottom: 0 !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		margin-bottom: 0 !important;
	}
	.carousel-indicators-bottom {
		bottom: 0 !important;
		margin-bottom: 0 !important;
	}
	h2 {
		font-size: 1.3rem !important;
	}
	h3 {
		font-size: 1.2rem !important;
	}
	h4 {
		font-size: 1.1rem !important;
	}
	h5 {
		font-size: 1rem !important;
	}
}

/*! NETFLIX CAROUSEL*/
.wrapper {
	display: grid;
	grid-template-columns: repeat(3, 100%);
	overflow: hidden;
	scroll-behavior: smooth;
}
.wrapper section {
	width: 100%;
	position: relative;
	display: grid;
	grid-template-columns: repeat(33, auto);
}
.wrapper section .item {
	transition: 250ms all;
}
.wrapper section .item:hover {
	transform: scale(1.05);
}

.wrapper section .item.selected {
	/* Add border*/
	border: 1px solid var(--primary-color);
	background: var(--info-color);
}

@media only screen and (max-width: 600px) {
	a.arrow__btn {
		display: none;
	}
}
/*#endregion*/
/*! ANIMATIONS*/
/*#region animations*/
.grow-over {
	/*Scale to 1*/
	transform: scale(1);
	transition: transform 0.5s;
}
/*on over grow-over*/
.grow-over:hover {
	/*Scale to 1.1*/
	transform: scale(1.1);
	transition: transform 0.5s;
}

.animate {
	animation-duration: 0.75s;
	animation-delay: 0.1s;
	animation-name: animate-fade;
	animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
	animation-fill-mode: backwards;
}

.animate-loop {
	animation: slideshow 1s linear infinite;
}

/*heart beat effect*/
.animate-heart {
	animation-name: heart-beat;
}

@keyframes heart-beat {
	0% {
		transform: scale(1);
	}
	10% {
		transform: scale(1.1);
	}
	20% {
		transform: scale(1);
	}
	40% {
		transform: scale(1.1);
	}
	50% {
		transform: scale(1);
	}
}

.animate-left {
	animation-name: animate-left;
}

@keyframes animate-left {
	0% {
		opacity: 0.5;
		transform: translate(-10px, 0);
	}
	100% {
		opacity: 1;
		transform: translate(5px, 0);
	}
}

.animate-right {
	animation-name: animate-right;
}

@keyframes animate-right {
	0% {
		opacity: 0.5;
		transform: translate(10px, 0);
	}
	100% {
		opacity: 1;
		transform: translate(-5px, 0);
	}
}

/* Fade In */
.animate.fade {
	animation-name: animate-fade;
	animation-timing-function: ease;
}

@keyframes animate-fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* Pop In */
.animate.pop {
	animation-name: animate-pop;
}

@keyframes animate-pop {
	0% {
		opacity: 0;
		transform: scale(0.5, 0.5);
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}

/* Blur In */
.animate.blur {
	animation-name: animate-blur;
	animation-timing-function: ease;
}

@keyframes animate-blur {
	0% {
		opacity: 0;
		filter: blur(15px);
	}
	100% {
		opacity: 1;
		filter: blur(0px);
	}
}

/* Glow In */
.animate.glow {
	animation-name: animate-glow;
	animation-timing-function: ease;
}

@keyframes animate-glow {
	0% {
		opacity: 0;
		filter: brightness(3) saturate(3);
		transform: scale(0.8, 0.8);
	}
	100% {
		opacity: 1;
		filter: brightness(1) saturate(1);
		transform: scale(1, 1);
	}
}

/* Grow In */
.animate.grow {
	animation-name: animate-grow;
}

@keyframes animate-grow {
	0% {
		opacity: 0;
		transform: scale(1, 0);
		visibility: hidden;
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}

/* Splat In */
.animate.splat {
	animation-name: animate-splat;
}

@keyframes animate-splat {
	0% {
		opacity: 0;
		transform: scale(0, 0) rotate(20deg) translate(0, -30px);
	}
	70% {
		opacity: 1;
		transform: scale(1.1, 1.1) rotate(15deg);
	}
	85% {
		opacity: 1;
		transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
	}

	100% {
		opacity: 1;
		transform: scale(1, 1) rotate(0) translate(0, 0);
	}
}

/* Roll In */
.animate.roll {
	animation-name: animate-roll;
}

@keyframes animate-roll {
	0% {
		opacity: 0;
		transform: scale(0, 0) rotate(360deg);
	}
	100% {
		opacity: 1;
		transform: scale(1, 1) rotate(0deg);
	}
}

/* Flip In */
.animate.flip {
	animation-name: animate-flip;
	transform-style: preserve-3d;
	perspective: 1000px;
}

@keyframes animate-flip {
	0% {
		opacity: 0;
		transform: rotateX(-120deg) scale(0.9, 0.9);
	}
	100% {
		opacity: 1;
		transform: rotateX(0deg) scale(1, 1);
	}
}

/* Spin In */
.animate.spin {
	animation-name: animate-spin;
	transform-style: preserve-3d;
	perspective: 1000px;
}

@keyframes animate-spin {
	0% {
		opacity: 0;
		transform: rotateY(-120deg) scale(0.9, 0.9);
	}
	100% {
		opacity: 1;
		transform: rotateY(0deg) scale(1, 1);
	}
}

/* Slide In */
.animate.slide {
	animation-name: animate-slide;
}

@keyframes animate-slide {
	0% {
		opacity: 0;
		transform: translate(0, 20px);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}

/* Drop In */
.animate.drop {
	animation-name: animate-drop;
	animation-timing-function: cubic-bezier(0.77, 0.14, 0.91, 1.25);
}

@keyframes animate-drop {
	0% {
		opacity: 0;
		transform: translate(0, -300px) scale(0.9, 1.1);
	}
	95% {
		opacity: 1;
		transform: translate(0, 0) scale(0.9, 1.1);
	}
	96% {
		opacity: 1;
		transform: translate(10px, 0) scale(1.2, 0.9);
	}
	97% {
		opacity: 1;
		transform: translate(-10px, 0) scale(1.2, 0.9);
	}
	98% {
		opacity: 1;
		transform: translate(5px, 0) scale(1.1, 0.9);
	}
	99% {
		opacity: 1;
		transform: translate(-5px, 0) scale(1.1, 0.9);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0) scale(1, 1);
	}
}

/* Animation Delays */
.delay-1 {
	animation-delay: 0.2s;
}
.delay-2 {
	animation-delay: 0.3s;
}
.delay-3 {
	animation-delay: 0.4s;
}
.delay-4 {
	animation-delay: 0.5s;
}
.delay-5 {
	animation-delay: 0.6s;
}
.delay-6 {
	animation-delay: 0.7s;
}
.delay-7 {
	animation-delay: 0.8s;
}
.delay-8 {
	animation-delay: 0.9s;
}
.delay-9 {
	animation-delay: 1s;
}
.delay-10 {
	animation-delay: 1.1s;
}
.delay-11 {
	animation-delay: 1.2s;
}
.delay-12 {
	animation-delay: 1.3s;
}
.delay-13 {
	animation-delay: 1.4s;
}
.delay-14 {
	animation-delay: 1.5s;
}
.delay-15 {
	animation-delay: 1.6s;
}
.delay-16 {
	animation-delay: 1.7s;
}
.delay-17 {
	animation-delay: 1.8s;
}
.delay-18 {
	animation-delay: 1.9s;
}
.delay-19 {
	animation-delay: 2s;
}
.delay-20 {
	animation-delay: 2.1s;
}
@media screen and (prefers-reduced-motion: reduce) {
	.animate {
		animation: none !important;
	}
}
/*#endregion*/
/*! Themes SPE */
/*#region themes*/
.Ochre {
	color: var(--light-color) !important;
	background-image: linear-gradient(
		19deg,
		var(--ochre-color) 0%,
		var(--ochre-light-color) 100%
	) !important;
}
.Organic {
	color: var(--light-color) !important;
	background-image: linear-gradient(
		19deg,
		var(--organic-color) 0%,
		var(--organic-light-color) 100%
	) !important;
}
.Slate {
	color: var(--light-color) !important;
	background-image: linear-gradient(
		19deg,
		var(--slate-color) 0%,
		var(--slate-light-color) 100%
	) !important;
}
.Plum {
	color: var(--light-color) !important;
	background-image: linear-gradient(
		19deg,
		var(--plum-color) 0%,
		var(--plum-light-color) 100%
	) !important;
}
.Mineral {
	color: var(--light-color) !important;
	background-image: linear-gradient(
		19deg,
		var(--mineral-color) 0%,
		var(--mineral-light-color) 100%
	) !important;
}

.btn-Ochre {
	background-image: linear-gradient(
		19deg,
		var(--ochre-color) 0%,
		var(--ochre-light-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--ochre-light-color) !important;
}
.btn-Ochre:active {
	background-image: linear-gradient(
		19deg,
		var(--ochre-light-color) 0%,
		var(--ochre-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--ochre-color) !important;
}

.btn-Organic {
	background-image: linear-gradient(
		19deg,
		var(--organic-color) 0%,
		var(--organic-light-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--organic-light-color) !important;
}
.btn-Organic:active {
	background-image: linear-gradient(
		19deg,
		var(--organic-light-color) 0%,
		var(--organic-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--organic-color) !important;
}

.btn-Slate {
	background-image: linear-gradient(
		19deg,
		var(--slate-color) 0%,
		var(--slate-light-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--slate-light-color) !important;
}
.btn-Slate:active {
	background-image: linear-gradient(
		19deg,
		var(--slate-light-color) 0%,
		var(--slate-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--slate-color) !important;
}

.btn-Plum {
	background-image: linear-gradient(
		19deg,
		var(--plum-color) 0%,
		var(--plum-light-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--plum-light-color) !important;
}
.btn-Plum:active {
	background-image: linear-gradient(
		19deg,
		var(--plum-light-color) 0%,
		var(--plum-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--plum-color) !important;
}

.btn-Mineral {
	background-image: linear-gradient(
		19deg,
		var(--mineral-color) 0%,
		var(--mineral-light-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--mineral-light-color) !important;
}
.btn-Mineral:active {
	background-image: linear-gradient(
		19deg,
		var(--mineral-light-color) 0%,
		var(--mineral-color) 100%
	) !important;
	color: var(--light-color) !important;
	border: solid 1px var(--mineral-color) !important;
}
/*#endregion*/
/*! TABLES */
/*#region tables*/
.text-blue-logo-dark {
	color: var(--logo-dark-color) !important;
}

/*! TABLE */

@media only screen and (min-width: 700px) {
	table {
		border-collapse: separate !important;
		border-spacing: 0 !important;
	}
	td,
	th {
		border-width: 0 !important;
	}
	tr:first-child th:first-child {
		border-top-left-radius: 6px !important;
	}
	tr:first-child th:last-child {
		border-top-right-radius: 6px !important;
	}
	tr:first-child th {
		border-top: solid 1px var(--primary-lighter-color) !important;
	}
	tr th:first-child,
	tr td:first-child {
		border-left: solid var(--primary-lighter-color) 1px !important;
	}

	tr:last-child td:first-child {
		border-bottom-left-radius: 6px !important;
	}
	tr:last-child td:last-child {
		border-bottom-right-radius: 6px !important;
	}

	tr:last-child td {
		border-bottom: solid 1px var(--primary-lighter-color) !important;
	}
	tr th:last-child,
	tr td:last-child {
		border-right: solid 1px var(--primary-lighter-color) !important ;
	}

	table.no-border tr td,
	table.no-border tr th {
		border-width: 0 !important;
	}
}
.sticky-t {
	position: sticky;
	top: 95;
	z-index: 200;
}

.table-dark {
	--bs-table-color: var(--light-color);
	--bs-table-bg: var(--slate-color) !important;
	--bs-table-border-color: var(--slate-color);
	--bs-table-striped-bg: var(--slate-light-color);
	--bs-table-striped-color: var(--light-color);
	--bs-table-active-bg: var(--slate-light-color);
	--bs-table-active-color: var(--light-color);
	--bs-table-hover-bg: var(--slate-light-color);
	--bs-table-hover-color: var(--light-color);
	--bs-table-color: var(--light-color) !important;
}

.table-primary {
	--bs-table-color: var(--light-color);
	--bs-table-bg: var(--organic-color) !important;
	--bs-table-border-color: var(--organic-color);
	--bs-table-striped-bg: var(--organic-light-color);
	--bs-table-striped-color: var(--light-color);
	--bs-table-active-bg: var(--organic-light-color);
	--bs-table-active-color: var(--light-color);
	--bs-table-hover-bg: var(--organic-light-color);
	--bs-table-hover-color: var(--light-color);
	--bs-table-color: var(--light-color) !important;
}

th {
	display: table-cell;
	vertical-align: middle;
	font-weight: normal !important;
	text-align: center;
	unicode-bidi: isolate;
}

td,
th {
	vertical-align: top;
}

@media only screen and (max-width: 700px) {
	.sticky-t {
		/*Nothing*/
		position: inherit;
		width: 100%;
	}
	thead th:not(:first-child) {
		display: none;
	}
	td,
	th {
		display: block;
		clear: both;
		border-width: 0px !important;
	}
	td[data-th]:before {
		content: attr(data-th);
		float: left;
	}
	colgroup {
		display: none;
	}
	.btn-table {
		width: 100% !important;
		height: 100% !important;
		max-height: 100% !important;
	}
	thead th:first-child {
		width: 100% !important;
		border-top-right-radius: 6px !important;
	}
}
/*#endregion*/
/*! DATATABLES*/
/*#region datatables*/
.dt-search,
.dt-length {
	margin: 4px;
	padding: 4px;
}
.dt-paging-button {
	border: solid 1px var(--slate-light-color);
	border-radius: 5px;
	background-image: linear-gradient(
		19deg,
		var(--slate-color) 0%,
		var(--slate-light-color) 100%
	) !important;
	color: var(--light-color);
	margin: 2px;
}
.dt-column-order {
	color: var(--light-color);
	margin: 2px;
}
/*#endregion*/
/*! RECAPTCHA */
/*#region recaptcha*/
.grecaptcha-badge {
	display: none !important;
}
/*#endregion*/
/*! MARGINS */
/*#region margins*/
.margin-left-xxs {
	margin-left: 1px !important;
}

.margin-top-bottom-sm {
	margin: 5px 0;
}
.content-margin-top {
	margin-top: 40px;
}
.app-side-margins {
	margin-left: 20px;
	margin-right: 20px;
}
.app-separator-medium {
	margin-top: 20px;
	margin-bottom: 20px;
}
.app-separator-large {
	margin-top: 40px;
	margin-bottom: 40px;
}
.app-separator-xlarge {
	margin-top: 80px;
	margin-bottom: 80px;
}
.app-separator-extreme {
	margin-top: 300px;
	margin-bottom: 300px;
}
.app-margin-header {
	margin-top: 100px;
	margin-bottom: 120px;
}
.icon-margin {
	margin-right: 10px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-5 {
	margin-top: 5px;
}
/*#endregion*/
/*! SHADOWS */
/*#region shadows*/
.shadow {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}
/*#endregion*/
/*! MODALS */
/*#region modals*/
.p-dialog-header-close {
	display: none !important;
}
.p-dialog-content {
	width: 100% !important;
}
.p-dialog,
.p-dialog-content {
	overflow-x: hidden !important;
}
.p-dialog-mask {
	background-color: rgba(0, 0, 0, 0.6) !important;
}
.modal-h-90 {
	min-height: 500px !important;
	max-height: 90% !important;
}
@media screen and (max-width: 700px) {
	.modal-h-90 {
		max-height: 90% !important;
		height: 90% !important;
	}
}
@media screen and (min-width: 701px) and (max-width: 768px) {
	.modal-h-90 {
		max-height: 90% !important;
		height: 90% !important;
	}
}

@media screen and (max-height: 700px) {
	.modal-h-90 {
		max-height: 90% !important;
		height: 90% !important;
	}
}
/*#endregion*/
/*! ACCORDIONS */
/*#region accordions*/
.p-accordion-header {
	color: var(--primary-color) !important;
	margin: 10px 0 !important;
	font-size: large;
	/*Remove underline*/
	text-decoration: none !important;
}
.p-accordion-header > a {
	text-decoration: none !important;
}
.p-accordion-header > a::after {
	content: none !important;
}
.p-accordion-header > a::before {
	content: none !important;
}
.p-accordion-toggle-icon {
	display: none !important;
}
/*#endregion*/
/*! SECTIONS */
/*#region sections*/
section {
	max-width: 1400px !important;
}
/*#endregion*/
/*! SELECT 2 */
/*#region select2*/

.select2-results__option,
.select2-selection__rendered {
	background-color: var(--background) !important;
	color: #000 !important;
}

.select2.select2-container {
	display: block;
	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--bs-body-color);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: var(--bs-body-bg);
	background-image: var(--bs-form-select-bg-img),
		var(--bs-form-select-bg-icon, none);
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-radius: var(--bs-border-radius);
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}
.select2-selection--single,
.select2-selection--multiple {
	border: none !important;
}
.select2-results__option {
	border-radius: 6px;
}

.select2-container {
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-radius: var(--bs-border-radius);
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}

.select2-search__field,
.select2-search__field:active,
.select2-search__field:focus {
	border: none !important;
}

.color-option[value="ochre"] {
	background-color: #7e684c !important;
	background-image: linear-gradient(19deg, #7e684c 0%, #b0997c 100%) !important;
	color: white !important;
}
.color-option[value="plum"] {
	background-color: #784949 !important;
	background-image: linear-gradient(19deg, #784949 0%, #af7a7a 100%) !important;
	color: white !important;
}
.color-option[value="organic"] {
	background-color: #467454 !important;
	background-image: linear-gradient(19deg, #467454 0%, #7aaf8a 100%) !important;
	color: white !important;
}
.color-option[value="mineral"] {
	background-color: #2c2c2c !important;
	background-image: linear-gradient(19deg, #2c2c2c 0%, #6f6f6f 100%) !important;
	color: white !important;
}
.color-option[value="slate"] {
	background-color: #495f78 !important;
	background-image: linear-gradient(19deg, #495f78 0%, #7a93af 100%) !important;
	color: white !important;
}
.color-option[value="blue"],
.color-option[value="blue"] {
	background-color: #35456b !important;
	background-image: linear-gradient(19deg, #35456b 0%, #c3cdea 100%) !important;
	color: white !important;
}
.color-option[value="red"] {
	background-color: #6f1b1b !important;
	background-image: linear-gradient(19deg, #8e2623 0%, #f27777 100%) !important;
	color: white !important;
}
.color-option[value="wood"] {
	background-color: #644939 !important;
	background-image: linear-gradient(19deg, #644939 0%, #9d8271 100%) !important;
	color: white !important;
}
.color-option[value="green"] {
	background-color: #28463c !important;
	background-image: linear-gradient(19deg, #28463c 0%, #688f4e 100%) !important;
	color: white !important;
}
.color-option[value="anis"] {
	background-color: #94b447 !important;
	background-image: linear-gradient(19deg, #94b447 0%, #bbcc66 100%) !important;
	color: white !important;
}
.color-option[value="aqua"] {
	background-color: #367bc3 !important;
	background-image: linear-gradient(19deg, #367bc3 0%, #b8e9e0 100%) !important;
	color: white !important;
}
.color-option[value="sky"] {
	background-color: #2d82b5 !important;
	background-image: linear-gradient(19deg, #2d82b5 0%, #88cdf6 100%) !important;
	color: white !important;
}
.color-option[value="monarch"] {
	background-color: #725a7a !important;
	background-image: linear-gradient(19deg, #725a7a 0%, #bfb8da 100%) !important;
	color: white !important;
}
.color-option[value="candy"] {
	background-color: #e79796 !important;
	background-image: linear-gradient(19deg, #e79796 0%, #ffc98b 100%) !important;
	color: white !important;
}
.color-option[value="pure"] {
	background-color: #6c8a9b !important;
	background-image: linear-gradient(19deg, #6c8a9b 0%, #e6e6e4 100%) !important;
	color: white !important;
}
.color-option[value="app-o²"] {
	background-color: #34333d !important;
	background-image: linear-gradient(19deg, #34333d 0%, #676675 100%) !important;
	color: white !important;
}
/*#endregion*/
/*! CHECKBOXES */
/*#region checkboxes*/
.checkbox-lg {
	/*Scale*/
	transform: scale(1.6) !important;
	margin-right: 16px;
}

.checkbox-xl {
	/*Scale*/
	transform: scale(1.6) !important;
	margin-left: 16px;
	margin-right: 16px;
}
/*#endregion*/
/*! Progress form*/
/*#region progress form*/
.step-container {
	position: relative;
	text-align: center;
	transform: translateY(-43%);
}

.step-circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: var(--light-lighter-color);
	border: 2px solid var(--primary-color);
	line-height: 30px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	cursor: pointer; /* Added cursor pointer */
}

.step-line {
	position: absolute;
	top: 16px;
	left: 50px;
	width: calc(100% - 100px);
	height: 2px;
	background-color: var(--primary-color);
	z-index: -1;
}

#multi-step-form {
	overflow-x: hidden;
}
/*#endregion*/

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.p-dialog .p-dialog-footer {
	background-image: linear-gradient(
		19deg,
		var(--primary-color) 0%,
		var(--primary-light-color) 100%
	) !important;
	color: var(--txt-primary) !important;
	padding: 0 1.5rem 1.5rem 1.5rem;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
}
.p-dialog .p-dialog-footer button {
	margin: 0 0.5rem 0 0;
	width: auto;
}

/*! SWIPPER*/

.autoplay-progress {
	position: absolute;
	right: 15px;
	bottom: 5px;
	z-index: 10;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: var(--light-color) !important;
}

.autoplay-progress svg {
	--progress: 0;
	position: absolute;
	left: 0;
	top: 0px;
	z-index: 10;
	width: 100%;
	height: 100%;
	stroke-width: 3px;
	stroke: var(--light-color) !important;
	fill: none;
	stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
	stroke-dasharray: 125.6;
	transform: rotate(-90deg);
}

.swiper-pagination-bullet {
	background: var(--primary-darker-color) !important;
	opacity: 0.4;
}
.swiper-pagination-bullet-active {
	color: var(--primary-color) !important;
	background: var(--primary-color) !important;
	opacity: 1;
}

/*! Swipe actions*/
.swipeable-list-item__leading-actions {
	top: 0;
}

.swipeable-list-item {
	max-height: none !important;
}
.nav-pills > .nav-item > .nav-link {
	background-color: var(--light-color);
	color: var(--primary-color);
}
.nav-pills > .nav-item > .nav-link.active {
	background-color: var(--primary-color);
	color: var(--light-color);
}

.title-mob {
	/**/
	margin: 0;
}
.toast-mob {
	/**/
	margin: 0;
}
.h0-mob {
	/**/
	font-size: 18px;
	margin: 0;
}
.h1-mob {
	/**/
	font-size: 16px;
	margin: 0;
}
.h2-mob {
	/**/
	font-size: 14px;
	margin: 0;
}
@media screen and (max-width: 800px) {
	.title-mob {
		font-size: 1.5rem !important;
		margin: 10px 0 !important;
	}
	.toast-mob {
		/**/
		font-size: 1.2rem !important;
	}
	.h0-mob {
		/**/
		font-size: 1.8rem;
	}
	.h1-mob {
		/**/
		font-size: 1.5rem;
	}
	.h2-mob {
		/**/
		font-size: 1.2rem;
	}
}

.cs-tooltip {
	position: relative;
	top: 25%;
	left: 50%;
}

.pointer-hand:hover {
	cursor: pointer;
}
.iframe-youtube {
	width: 560px;
	height: 315px;
}

@media screen and (max-width: 800px) {
	.iframe-youtube {
		height: 180px;
		/*Width: calc(height *1.7778) */
		width: 355.56px;
	}
}


.city-search-container {
	margin-bottom: 20px;
  }
  
  .horizontal-scroll-container {
	display: flex;
	overflow-x: auto;
	padding: 10px 0;
  }
  
  .company-card {
	flex: 0 0 auto;
	width: 200px;
	margin-right: 15px;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	text-align: center;
  }
  
  .company-image {
	width: 100%;
	height: 100px;
	object-fit: contain;
	margin-bottom: 10px;
  }
  
  .company-card h5 {
	margin-bottom: 10px;
	font-size: 14px;
  }
  
  .company-card .btn {
	font-size: 12px;
  }
  